import React, { lazy, Suspense } from "react";
import styles from "./HomePage.module.css";
import Spinner from "../components/Spinner/Spinner";
import { Outlet } from "react-router-dom";
const Header = lazy(() => import("../components/Header/Header"));
const Footer = lazy(() => import("../components/Footer/Footer"));

interface HomePageProps {
  isLuxuriesModalOpen: boolean;
  isAttractionsModalOpen: boolean;
  isContactModalOpen: boolean;
  isGalleryModalOpen: boolean;
  isRatesModalOpen: boolean;
  isReservationsModalOpen: boolean;
  isReviewsModalOpen: boolean;
  isSiteMapModalOpen: boolean;
  isTermsModalOpen: boolean;
  isWeatherModalOpen: boolean;
  closeLuxuriesModal: () => void;
  closeAttractionsModal: () => void;
  closeContactModal: () => void;
  closeGalleryModal: () => void;
  closeRatesModal: () => void;
  closeReservationsModal: () => void;
  closeReviewsModal: () => void;
  closeSiteMapModal: () => void;
  closeTermsModal: () => void;
  closeWeatherModal: () => void;
  openLuxuriesModal: () => void;
  openAttractionsModal: () => void;
  openContactModal: () => void;
  openGalleryModal: () => void;
  openRatesModal: () => void;
  openReservationsModal: () => void;
  openReviewsModal: () => void;
  openSiteMapModal: () => void;
  openTermsModal: () => void;
  openWeatherModal: () => void;
}

const HomePage: React.FC<HomePageProps> = (props) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    setModalOpen(
      props.isLuxuriesModalOpen ||
        props.isAttractionsModalOpen ||
        props.isContactModalOpen ||
        props.isGalleryModalOpen ||
        props.isRatesModalOpen ||
        props.isReservationsModalOpen ||
        props.isReviewsModalOpen ||
        props.isSiteMapModalOpen ||
        props.isTermsModalOpen ||
        props.isWeatherModalOpen,
    );
  }, [
    props.isLuxuriesModalOpen,
    props.isAttractionsModalOpen,
    props.isContactModalOpen,
    props.isGalleryModalOpen,
    props.isRatesModalOpen,
    props.isReservationsModalOpen,
    props.isReviewsModalOpen,
    props.isSiteMapModalOpen,
    props.isTermsModalOpen,
    props.isWeatherModalOpen,
  ]);

  return (
    <div className={`${styles.app} ${isModalOpen ? styles.noScroll : ""}`}>
      <Suspense fallback={<Spinner />}>
        <Header />
        <Outlet />
        <Footer />
      </Suspense>
    </div>
  );
};

export default HomePage;
