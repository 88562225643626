import { FC } from "react";
import styles from "../PoochBeach/PoochBeach.module.css";

interface Props {
  titleText: string;
  descText: string;
  scr?: string;
  alt?: string;
}

const LayoutRightSection: FC<Props> = (props) => {
  const { titleText, descText, scr, alt } = props;
  return (
    <div className={styles.poochBeachContainer} style={{ marginBottom: "5%" }}>
      <div className={styles.textSection}>
        <div className={styles.textContainer}>
          <div className={styles.rightTitle}>{titleText}</div>
          <div className={styles.rightText}>{descText}</div>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={scr}
          alt={alt}
          className={styles.image}
          loading="eager"
          draggable={false}
          decoding="async"
        />
      </div>
    </div>
  );
};

export default LayoutRightSection;
