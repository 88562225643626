import { FC } from "react";
import stylesExp from "./ExperiencePage.module.css";
import stylesHomePage from "./HomePage.module.css";
import { altImages } from "./HomePageMain";
import LayoutRightSection from "../components/layoutRightTextSection/LayoutRightSection";
import LayoutLeftSection from "../components/layoutLeftTextSection/LayoutLeftSection";
import stylesLastSection from "../components/LastHomepageSection/LastHomepageSection.module.css";
import styles from "./LuxuriesPage.module.css";
import ResponsiveImages from "../components/Reponsive-images/ReponsiveImages";

interface DataSection {
  titleText: string;
  descText: string;
  scr: string;
  alt: string;
}

const dataSectionRight: DataSection[] = [
  {
    titleText: "Navarre Beach Fishing Pier",
    descText:
      "The Navarre Pier is a record-holding pier in Florida and the longest fishing pier in the Gulf of Mexico. At 1,545 feet long, it is the fifth longest pier in the United States. You can rent fishing poles from the local shop and enjoy a meal at the outdoor restaurant and enjoy the beautiful sunsets on the Gulf.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-sea.png",
    alt: altImages,
  },
  {
    titleText: "Navarre Florida Watersports",
    descText:
      "Navarre Florida Watersports offers rentals and activities to all ages! They offer Pontoons, Double-Decker Pontoon, Wave runners, Wibit Waterpark, Paddle Boards, Kayaks, Snorkel Equipment, and much more. Only a seven-minute drive from The Hideaway Retreat for fun with the whole family.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Seadoo.jpeg",
    alt: altImages,
  },
  {
    titleText: "Gulf Breeze Zoo",
    descText:
      "Experience a zoo that invites you to not only look at the animals but experience them through unique animal encounters, animals feeding, and much more. They are home to over 1,000 animals ranging from all over the world.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/jiraffe-wuuu.jpg",
    alt: altImages,
  },
  {
    titleText: "Florida Zipline Adventures",
    descText:
      "Soar through the air and get a bird’s eye view of the forest, beaches, and river below.  Adventures Unlimited Zip Adventures combines the adrenaline rush of a zip line canopy tour with the beauty of Northwest Florida’s pristine longleaf pine forest and Coldwater Creek.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Adventure+Unlimited+ZIp.jpeg",
    alt: altImages,
  },
  {
    titleText: "The Equestrian Center - Horse Back Riding",
    descText:
      "The Equestrian Center offers horseback riding for beginners, all the way through advanced riders. Enjoy rides on award winning horses and ponies, as well as many more interesting opportunities.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Equestrian+Center.jpeg",
    alt: altImages,
  },
  {
    titleText: "Navarre Beach Sea Turtle Conservation Center",
    descText:
      "Here you will learn so much more about sea turtles, native species of the emerald coast and non-native species, as well as tons of interactive educational displays. See turtles in person and learn how to better  help them in the wild.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-turtol.png",
    alt: altImages,
  },
  {
    titleText: "Blackwater River State Park",
    descText:
      "Blackwater River is the largest contiguous longleaf pine/wiregrass ecosystem remaining in the world, one described as being rarer than a tropical rainforest. At Blackwater River you can experience an abundance of fun, which includes bicycling, fishing, paddle boarding/kayaking, and more.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-waterfall.png",
    alt: altImages,
  },
  {
    titleText: "Seaside, Florida",
    descText:
      "Seaside is a small resort community in northwest Florida, on the Gulf of Mexico. It’s known for its late-20th-century New Urbanist design, as well as pastel-colored houses featuring porches and white picket fences. Plenty of shopping and good food here in this beautiful town. Fun Fact, the Truman Show was filmed here!",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/seaside_florida.jpg",
    alt: altImages,
  },
  {
    titleText: "Golfing",
    descText:
      "There is plenty of Golfing in and around Navarre! Some of our favorite places are The Club at Hidden Creek or Tiger Point Golf Club. Find beautiful greens and enjoy a day in the sun, on the course.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/The+Club+.jpeg",
    alt: altImages,
  },
  {
    titleText: "Music Events/Shows",
    descText:
      "There is an abundance of concerts, musical events, comedy shows, and more near Navarre. Pensacola, only a 30-minute drive, is a hub for many concerts. Panama City is another great option for big artists in the local area!",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Concert.jpeg",
    alt: altImages,
  },
];

const dataSectionLeft: DataSection[] = [
  {
    titleText: "Sandcastle Lessons",
    descText:
      "Have you ever built a 3.5 ft sand sculpture?  This      two hour lesson is led by a trained sand sculpture artist from Beach Sand Sculptures who will help you create a work of art.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-shore.png",
    alt: altImages,
  },
  {
    titleText: "Surf Lessons",
    descText:
      "Want to catch some waves? Your instructor with Ride on Surf School will teach you how to stand up, get your balance, and why surf wax is a beginner’s best friend.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Surf+Lessons.jpeg",
    alt: altImages,
  },
  {
    titleText: "Pirate Cruise",
    descText:
      "Unlock the treasure of your heart as you embark on a thrilling pirate adventure aboard the Buccaneer Pirate Cruise.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/destin-pirate-ship.jpg",
    alt: altImages,
  },
  {
    titleText: "Seablaster",
    descText:
      "Climb aboard the Gulf’s largest speedboat while  exploring the emerald waters in search of dolphins, having water gun fights on daytime cruises and enjoying the beautiful sunset cruises or Destin Harbor Fireworks cruises.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-boat.png",
    alt: altImages,
  },
  {
    titleText: "Dolphin Cruise",
    descText:
      "Set out to sea in search of dolphins aboard the glass bottom boat, Hannah Marie. The Destin Dolphin Watch and Crab Island Sightseeing Cruise is the perfect trip for maritime bliss.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/luxury-delphin.png",
    alt: altImages,
  },
  {
    titleText: "Snorkeling",
    descText:
      " Explore our beautiful emerald- green waters from within the water while discovering various fish and marine life with Snorkeling and Shelling Destin.  Pick up a few seashells to keep as souvenirs along the way.",
    scr: "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/Snorkeling.jpeg",
    alt: altImages,
  },
];

const LuxuriesPages: FC = () => {
  return (
    <div className={stylesExp.layoutSubPagesSub}>
      <div className={stylesExp.boxTitle}>
        <h1 className={stylesExp.titleExperience}>Luxuries</h1>
        <p className={styles.sectionText}>
          You are enough. You have enough. You do enough. Now relax.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft="50% 50%"
        objectPositionRight="50% 20%"
        rightImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/IMG_5217.jpeg"
        leftImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-1.jpg"
      />
      <div className={stylesExp.boxTitle}>
        <h1 className={stylesExp.titleExperience}>Our Amenities</h1>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>742 ft of Beachfront</h2>
        <p className={stylesHomePage.sectionText}>
          Enjoy both our Vista Seawall with 371 ft of beach plus our Hidden
          Beach below with 371 ft, totaling 742 ft of private beach! A truly
          unique beach setting.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>350 ft. Fishing Pier</h2>
        <p className={stylesHomePage.sectionText}>
          Coming soon…with two special features that you will love! No fishing
          license required.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>The Hangout</h2>
        <p className={stylesHomePage.sectionText}>
          Meet your neighbor and make new friends at The Hangout sitting around
          our Community Firepit under unique oak trees with beautiful streaming
          lights while enjoying outdoor games.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Outdoor Games</h2>
        <p className={stylesHomePage.sectionText}>
          Get back to the basics enjoying outdoor games like cornhole, ping
          pong, volleyball, foosball, ring toss and more.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Dog Beach</h2>
        <p className={stylesHomePage.sectionText}>
          Enjoy beach day with your fur baby on our 371 ft Pooch Beach.
          <br />
          Free WIFI
          <br />
          Full Hookup RV Sites
        </p>
      </div>
      <ResponsiveImages
        objectPositionRight="50% 90%"
        rightImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/323rdfsdf.jpg"
        leftImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-6.jpg"
      />
      <div className={stylesExp.boxTitle}>
        <h1 className={stylesExp.titleExperience}>Activities</h1>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Vista Seawall</h2>
        <p className={stylesHomePage.sectionText}>
          Enjoy our Vista Seawall with 371 ft of white beach sand overlooking
          The Sound while catching the most colorful and unique sunsets you’ve
          ever seen.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Hidden Beach</h2>
        <p className={stylesHomePage.sectionText}>
          You’ll love our 371 ft private beach on the calm and shallow water of
          The Sound that’s both kid and pet friendly. We like to call it “Mother
          Nature’s Wading Pool".
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>The Hangout</h2>
        <p className={stylesHomePage.sectionText}>
          Enjoy a campfire at our community firepit at The Hangout with lights
          streaming in our beautiful oak trees while playing outdoor games.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Outdoor Games</h2>
        <p className={stylesHomePage.sectionText}>
          You’ll love hanging out playing our outdoor games like cornhole, ping
          pong, volleyball, foosball, ring toss and more.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Pooch Beach</h2>
        <p className={stylesHomePage.sectionText}>
          Have fun on beach day with your fur baby, enjoying all 371 ft of our
          Pooch Beach! They will love it and so will you.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Paddle Board/ Kayak Rentals</h2>
        <p className={stylesHomePage.sectionText}>
          Experience The Hideaway from the water, on The Sound, which is perfect
          for paddle boarding and kayaking. New boards and kayaks coming this
          spring.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Fishing Pier</h2>
        <p className={stylesHomePage.sectionText}>
          Fish right off our 350 ft fishing pier without even worrying about
          getting a fishing license (coming soon). Enjoy incredible sunsets
          right out in the middle of the Sound.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>More to Come…</h2>
        <p className={stylesHomePage.sectionText}>
          Exciting new activities coming soon.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft="50% 75%"
        objectPositionRight="50% 75%"
        leftImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/lux-sec-three-left.png"
        rightImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/lux-sec-three-right.png"
      />
      <div className={stylesExp.boxTitle}>
        <h1 className={stylesExp.titleExperience}>Facilities</h1>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Camp Lobby</h2>
        <p className={stylesHomePage.sectionText}>
          Check in at our Shabby Chic Camp Lobby and experience why everyone
          says we’re "the friendliest RV Resort”.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Laundry </h2>
        <p className={stylesHomePage.sectionText}>
          Our guest’s say that our upgraded laundry room…” belongs on HGTV!”
          Washers / Dryers are both coin and credit card operated. And with a
          Free App to alert you when your wash is done, you’ll enjoy doing
          laundry.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Waterfront Cottage</h2>
        <p className={stylesHomePage.sectionText}>
          5Bed/ 3 Bath waterfront cottage, sleeps 12, with the most spectacular
          views coming soon.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={styles.subTitleText}>Vintage Camper Rental</h2>
        <p className={stylesHomePage.sectionText}>
          1958 Shasta camper, “Shabby Shasta” sleeps two adults and is
          guaranteed to take you back in time.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft="50% 75%"
        objectPositionRight="50% 90%"
        rightImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/unnamed-69.jpg"
        leftImage="https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/lux-sec-four-left.png"
      />
      <div className={stylesExp.boxTitle} id="attractions">
        <h1 className={stylesExp.titleExperience} style={{ margin: 20 }}>
          Attractions
        </h1>
      </div>
      {dataSectionRight.map((item, index) => (
        <LayoutRightSection
          key={index}
          titleText={item.titleText}
          descText={item.descText}
          scr={item.scr}
          alt={item.alt}
        />
      ))}
      {dataSectionLeft.map((item, index) => (
        <LayoutLeftSection
          key={index}
          titleText={item.titleText}
          descText={item.descText}
          scr={item.scr}
          alt={item.alt}
        />
      ))}
      <div className={stylesLastSection.lastHomepageSection}>
        <div className={stylesLastSection.text}>
          There is so much to do when visiting our beautiful beaches of the
          Emerald Coast. This is a list of just some of our favorites. We can’t
          wait to hear about your day trips and see your social media posts of
          all the memories you’ve created!
        </div>
        <div className={stylesLastSection.text}>
          “We believe a life without memories is a life not living”
        </div>
      </div>
    </div>
  );
};

export default LuxuriesPages;
