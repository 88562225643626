import { PRINT_CAMP_MAP, SAVE_PHOTO } from "../actions/campMapActions";
import { savePhotoReducer } from "./savePhotoReducer";

const initialState = {
  // Define your initial state here
};

const campMapReducer = (state = initialState, action: { type: string }) => {
  switch (action.type) {
    case PRINT_CAMP_MAP:
      // Implement your printCampMap logic here
      // Call the necessary code to trigger the printing functionality
      // For example: window.print();
      return state;
    case SAVE_PHOTO:
      savePhotoReducer();
      return state;
    default:
      return state;
  }
};

export default campMapReducer;
