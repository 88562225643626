import { FC } from "react";
import stylesLayout from "./ExperiencePage.module.css";
import styles from "./ForYourTripPage.module.css";
import stylesHomePage from "./HomePage.module.css";
import stylesExp from "./ExperiencePage.module.css";
import "maplibre-gl/dist/maplibre-gl.css";
import { altImages } from "./HomePageMain";

const ForYourTripPage: FC = () => {
  return (
    <div className={stylesLayout.layoutSubPagesSub}>
      <div className={stylesLayout.boxTitle}>
        <h1 className={stylesLayout.titleExperience}>For Your Trip...</h1>
        <p className={styles.subTitle}>Check In 12-5pm / Check Out 11am</p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>
          Your Day of Arrival
        </h2>
        <p className={stylesHomePage.sectionText}>
          Check in is between 12-5pm CST. Upon arrival, guest must check in at
          the office and register vehicle prior to parking in site even if guest
          has already checked in online. All vehicles must have a parking pass
          displayed on dash or vehicle is subject to be towed. Guest is fully
          responsible for safely maneuvering through park and parking into their
          site; therefore, we suggest your partner assisting you if one is
          available. As a courtesy, we may help assist guest parking into their
          site as an "additional spotter” if requested. Please call the office
          850-939-2717 for assistance.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>Late Arrivals</h2>
        <p className={stylesHomePage.sectionText}>
          Check in is between 12-5pm CST, therefor late arrivals must call the
          office at 850-939-2717 for parking instructions prior to your day of
          arrival. Guest must call the office at 850-939-2717 before 5pm for after 
          hours instructions no later than the day of arrival.</p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>Departures</h2>
        <p className={stylesHomePage.sectionText}>
          Check out is at 11am. Please stop by the office to check out and
          return your Parking Pass and any borrowed items or a fee will apply.
          Late checkouts must be approved by the office and a late checkout fee
          to apply. Guest is responsible for safely maneuvering through park;
          therefore, we suggest your partner assisting you if one is available.
          As a courtesy, we may help assist you out of your site, as an
          “additional spotter” if requested. Please call the office 850-939-2717
          for assistance.
        </p>
      </div>
      <div className={stylesHomePage.section}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>
          Cancellation Policy
        </h2>
        <p className={stylesHomePage.sectionText}>
          Guest must cancel 14 days or sooner from their date of arrival to
          receive a full refund minus a $35 booking fee. Guest may reschedule
          reservation dates prior to 14 days of arrival date and receive a full
          credit toward next stay, minus a $35 booking. No refunds will be given
          for cancellations made within 14 days of arrival date. No Refunds for
          early departure for any reason. No Refunds due to weather or acts of
          nature of any kind. Monthly reservations require a 30 day cancellation
          and a $500 non- refundable deposit.
        </p>
      </div>
      <div className={stylesHomePage.section} style={{ marginBottom: 0 }}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>Terms</h2>
        <p className={stylesHomePage.sectionText}>
          Rates are subject to change at The Hideaway Retreat’s discretion.
          Sites are limited to 6 people per site, the first two are
          complimentary with an additional charge for extra people. Two vehicles
          are allowed per site, the first is complimentary, a daily charge to
          apply for extra vehicles. We have a 3 dog maximum and a small pet fee
          to apply. Visitors of guests must check in at office and register
          vehicle, or vehicle is subject to be towed, and a daily visitor charge
          to apply. Children must be supervised by an adult at all times. No
          firepits, candles, or tiki torches at site, we offer a beautiful
          community real wood burning firepit with outdoor games for you to
          enjoy. When booking, you are guaranteed a site, not a specific site
          number. However, you may lock in a specific site number to get the
          site of your choice for an additional fee. Please call the office for
          details and for information regarding additional terms and conditions.
        </p>
      </div>
      <div className={stylesHomePage.section} id="campmap">
        <h2 className={stylesExp.titleExperience}>Camp Map</h2>
        <p
          className={stylesHomePage.sectionText}
          style={{ padding: 0, margin: 0 }}
        >
          A true vintage campground layout…how we feel camping should be.
        </p>
      </div>
      <div className={styles.boxImage}>
        <img
          src={
            "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/New+Site+Map+(6)-1.png"
          }
          alt={altImages}
          className={styles.imageMap}
          loading="eager"
          draggable="false"
          decoding="async"
          title={altImages}
        />
      </div>

      <div className={stylesHomePage.section}>
        <div style={{ marginTop: "5%", padding: "0 17%", textAlign: "center" }}>
          <h2 className={stylesHomePage.sectionText} style={{ width: "100%" }}>
            There’s nothing cookie cutter about The Hideaway Retreat. Each
            campsite is unique in size and shape with its own character.
          </h2>
        </div>
      </div>
      <div className={stylesHomePage.section} style={{ marginBottom: 40 }}>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>
          May all who come as guests, leave as friends.
        </h2>
        <h2 className={stylesHomePage.sevenFortyTwoTitle}>
          We look forward to hosting you!
        </h2>
        <p className={stylesHomePage.sectionText}></p>
      </div>
    </div>
  );
};

export default ForYourTripPage;
