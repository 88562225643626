import { configureStore, combineReducers } from "@reduxjs/toolkit";
import videoBufferReducer from "./reducers/videoBuffer";
import campMapReducer from "./reducers/campMapReducer";
import modalReducer from "./reducers/modalReducer";

const rootReducer = combineReducers({
  videoBuffer: videoBufferReducer,
  campMap: campMapReducer,
  modal: modalReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const dispatch = store.dispatch;
