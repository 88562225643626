import { FC, CSSProperties } from "react";
import stylesImage from "../ImagesSectionTwo/ImagesSectionTwo.module.css";

interface Props {
  src?: string;
  alt?: string;
  sx?: CSSProperties;
}

const ImagesSections: FC<Props> = (props) => {
  const { src, alt, sx } = props;
  return (
    <div className={stylesImage.imagesSection}>
      <img
        src={src}
        alt={alt}
        style={sx}
        className={sx ? stylesImage.image : ""}
        loading="eager"
        draggable="false"
        decoding="async"
        title={alt}
      />
    </div>
  );
};

export default ImagesSections;
