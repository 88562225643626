import { FC } from "react";
import styles from "./ReponsiveImages.module.css";

interface ResponsiveImagesProps {
  leftImage: string;
  rightImage: string;
  altRight?: string;
  altLeft?: string;
  objectPositionRight?: string;
  objectPositionLeft?: string;
}

const ResponsiveImages: FC<ResponsiveImagesProps> = (props) => {
  const {
    rightImage,
    leftImage,
    objectPositionRight,
    objectPositionLeft,
    altLeft,
    altRight,
  } = props;
  return (
    <div className={styles.container}>
      <div className={styles.leftImageContainer}>
        <img
          src={leftImage}
          alt={altLeft}
          style={{ objectPosition: objectPositionLeft }}
          className={styles.image}
        />
      </div>
      <div className={styles.rightImageContainer}>
        <img
          src={rightImage}
          alt={altRight}
          style={{ objectPosition: objectPositionRight }}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default ResponsiveImages;
