// videoBuffer.ts

import { createSlice } from "@reduxjs/toolkit";

interface VideoBufferState {
  isBuffering: boolean;
}

const initialState: VideoBufferState = {
  isBuffering: false,
};

const videoBufferSlice = createSlice({
  name: "videoBuffer",
  initialState,
  reducers: {
    startBuffering: (state) => {
      state.isBuffering = true;
    },
    stopBuffering: (state) => {
      state.isBuffering = false;
    },
  },
});

export const { startBuffering, stopBuffering } = videoBufferSlice.actions;

export default videoBufferSlice.reducer;
