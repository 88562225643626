import React from "react";
import RootLayout from "./layout/RootLayout";
import "./App.css";
import HomePage from "./pages/HomePage";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import awsmobile from "./aws-exports";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import HomePageMain from "./pages/HomePageMain";
import ExperiencePage from "./pages/ExperiencePage";
import ForYourTripPage from "./pages/ForYourTripPage";
import LuxuriesPages from "./pages/LuxuriesPage";
import PictureBookPage from "./pages/PictureBookPage";

Amplify.configure({
  ...awsmobile,
  Storage: {
    bucket: "hero-video20842-staging",
    region: "us-east-1",
  },
});

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <RootLayout>
            <Routes>
              <Route
                path="/"
                element={
                  <HomePage
                    isLuxuriesModalOpen={false}
                    isAttractionsModalOpen={false}
                    isContactModalOpen={false}
                    isGalleryModalOpen={false}
                    isRatesModalOpen={false}
                    isReservationsModalOpen={false}
                    isReviewsModalOpen={false}
                    isSiteMapModalOpen={false}
                    isTermsModalOpen={false}
                    isWeatherModalOpen={false}
                    closeLuxuriesModal={() => {}}
                    closeAttractionsModal={() => {}}
                    closeContactModal={() => {}}
                    closeGalleryModal={() => {}}
                    closeRatesModal={() => {}}
                    closeReservationsModal={() => {}}
                    closeReviewsModal={() => {}}
                    closeSiteMapModal={() => {}}
                    closeTermsModal={() => {}}
                    closeWeatherModal={() => {}}
                    openLuxuriesModal={() => {}}
                    openAttractionsModal={() => {}}
                    openContactModal={() => {}}
                    openGalleryModal={() => {}}
                    openRatesModal={() => {}}
                    openReservationsModal={() => {}}
                    openReviewsModal={() => {}}
                    openSiteMapModal={() => {}}
                    openTermsModal={() => {}}
                    openWeatherModal={() => {}}
                  />
                }
              >
                <Route index element={<HomePageMain />} />
                <Route path="experience" element={<ExperiencePage />} />
                <Route path="picture-book" element={<PictureBookPage />} />
                <Route path="luxuries" element={<LuxuriesPages />} />
                <Route path="for-your-trip" element={<ForYourTripPage />} />
              </Route>
            </Routes>
          </RootLayout>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
